import React from 'react';
import cx from 'classnames';

import Invertase from '../../themes/invertase';
import Hero from '../../themes/invertase/Hero';
import Link from '../../components/Link';
import Container from '../../components/Container';

import projects from '../../../config/projects';

import styles from './styles.module.scss';

class OSS extends React.PureComponent {
  get categories() {
    const categories = [];
    projects.byArray.forEach(({ category }) => {
      for (let i = 0, len = category.length; i < len; i++) {
        if (!categories.includes(category[i])) categories.push(category[i]);
      }
    });
    return categories;
  }

  get projects() {
    const byArray = projects.byArray;

    if (!Object.keys(this.state.active).length) return byArray;

    const active = Object.keys(this.state.active);

    return byArray.filter(project => {
      for (let i = 0, len = active.length; i < len; i++) {
        if (project.category.includes(active[i])) return true;
      }

      return false;
    });
  }

  state = {
    active: {},
  };

  _onTagClick = tag => {
    const active = { ...this.state.active };

    if (active[tag]) delete active[tag];
    else active[tag] = true;

    this.setState({ active });
  };

  render() {
    return (
      <Invertase theme={'transparent'}>
        <Hero className={styles.hero} theme={'subtle-prism'}>
          <Container>
            <h1>We're passionate about Open Source software</h1>
            <h2>
              Open source empowers the community with innovation and freedom.
            </h2>
          </Container>
        </Hero>
        <div className={styles.content}>
          <Container>
            <h2>Open Source projects</h2>
            <div className={styles.grid}>
              <div>
                <ul className={styles.tags}>
                  {this.categories.map(c => (
                    <li
                      onClick={() => this._onTagClick(c)}
                      className={cx({
                        [styles.active]: !!this.state.active[c],
                      })}
                    >
                      {c}
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                {this.projects.map(project => (
                  <div key={project.key} className={styles.project}>
                    <h3>
                      <img src={project.logo} alt={project.name} />
                      <Link to={`/oss/${project.npm}`}>{project.name}</Link>
                    </h3>
                    <p>{project.description}</p>
                    Github | Docs | NPM
                  </div>
                ))}
              </div>
            </div>
          </Container>
        </div>
      </Invertase>
    );
  }
}

export default OSS;
